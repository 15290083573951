<template>
  <b-form-input
    v-model="dateValue"
    type="date"
    :min="minDate"
    :max="maxDate"
    @input="dateValueUpdated"
  />
</template>

<script>
export default {
  name: 'SchemaFormDateField',
  props: {
    value: String,
    field: Object,
  },
  data () {
    return {
      dateValue: null,
      normalizedDateValue: null,
    }
  },
  computed: {
    minDate() {
      return this.field?.inputMeta?.date?.min || null
    },
    maxDate() {
      return this.field?.inputMeta?.date?.max || null
    },
    defaultDate() {
      return this.field?.inputMeta?.date?.default || null
    },
  },
  async mounted () {
    await this.$nextTick()
    this.dateValue = this.value ? this.convertToDatePickerFormat(this.value) : this.defaultDate || ''
  },
  methods: {
    dateValueUpdated () {
      this.normalizedDateValue = this.normalizeDate(this.dateValue)
      this.field.isValid = !!this.normalizedDateValue
      this.$emit('input', this.normalizedDateValue)
    },
    normalizeDate (date) {
      if (date && date !== '') {
        const tempDateTime = new Date(date)
        const [tempYear, tempMonth, tempDate] = date.split('-')
        return tempDateTime.getFullYear().toString().length === 4 ? [tempMonth, tempDate, tempYear].join('/') : null
      } else {
        return null
      }
    },
    // Converts date from MM/dd/yyyy to yyyy-MM-dd
    convertToDatePickerFormat(date) {
      if (!date) { return }

      const tempDate = new Date(date)
      let month = tempDate.getMonth() + 1
      month = month < 10 ? '0' + month : month
      let day = tempDate.getDate()
      day = day < 10 ? '0' + day : day
      return [tempDate.getFullYear(), month, day].join('-')
    },
    validateDate () {
      const isValid = this.normalizedDateValue
      this.field.isValid = isValid
      this.field.validationError = isValid ? null : 'Invalid Date, must be in MM/DD/YYYY format'
    },
  },
}
</script>
